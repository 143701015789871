import { gsap } from 'gsap';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import FontFaceObserver from 'fontfaceobserver';
import { saveSnapshot } from './snapshot.js';
import materials from './materials.js';
import { customizationOptions } from './customizeOptions.js';
import { loadAndApplyTextureToMaterial, disposeTexture } from './textureManager.js';
import { hdrTexturePath } from './config.js';
import { initMaterials } from './materialChange.js';
import { 
  fetchProductVariants, 
  addToCart, 
  fetchCartData,
} from './shopifyClientApi.js';
import { shopifyProductId } from '../config/configKeys.js'; // Adjust the import path based on the actual file structure

const font = new FontFaceObserver('Oswald');

font.load().then(() => {
    // Apply your font-related code here, such as rendering text with the Oswald font
}).catch((error) => {
    console.error('Font could not be loaded:', error);
});

  const preloader = document.getElementById('preloader');
  const introLogo = document.getElementById('introLogo');

let camera, renderer, scene, controls;
let lightGroup;
let clock = new THREE.Clock();
let mesh;
let logoMaterial;
let customLogoTexture = null;
let meshName;
let delta;

let textureCount = 0; 

const container = document.querySelector('.scene-container');

const sizes = {
  width: container.clientWidth,
  height: container.clientHeight
};

// Create the Three.js scene, camera, and renderer
scene = new THREE.Scene();
scene.background = new THREE.Color(0xf6f6f6);

camera = new THREE.PerspectiveCamera( 60, sizes.width / sizes.height, 0.01, 100 );

renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });
renderer.sortObjects = false;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.VSMShadowMap;
renderer.useLegacyLight = false;
renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
renderer.toneMapping = THREE.ReinhardToneMapping;
renderer.toneMappingExposure = 1;

renderer.setSize( sizes.width, sizes.height );
const canvas = renderer.domElement;
container.appendChild(canvas);

function handleResize() {
  // Update Screensize
  sizes.width = container.clientWidth;
  sizes.height = container.clientHeight;

  // Update Camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update Renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
}

// Call the function to set up initial sizes and rendering
handleResize();

// Listen for window resize events and call the resizing function
window.addEventListener('resize', handleResize);

const fullscreenBtn = document.getElementById('fullscreenBtn');

fullscreenBtn.addEventListener('click', () => {
  const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement;

  if (!fullscreenElement) {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
});

// Create a raycaster
const raycaster = new THREE.Raycaster();
raycaster.layers.set(1);
const mouse = new THREE.Vector2();

// Flag to indicate mouse movement
let isMouseMoving = false;
let isRaycasterActive = true;

function calculateMouseCoords(event) {
  // Calculate mouse coordinates in normalized device coordinates (NDC)
  mouse.x = (event.clientX / sizes.width) * 2 - 1;
  mouse.y = -(event.clientY / sizes.height) * 2 + 1;

  // Set the raycaster to shoot a ray from the camera to the mouse position
  raycaster.setFromCamera(mouse, camera);

  // Detect intersected objects
  const intersects = raycaster.intersectObjects(scene.children, true);

  return intersects;
}

renderer.domElement.addEventListener('mousedown', onMouseDown);
renderer.domElement.addEventListener('mousemove', onMouseMove);

function onMouseDown(event) {
  if (!isRaycasterActive) {
    return;
  }

  isMouseMoving = false;

  const intersects = calculateMouseCoords(event);

  if (intersects.length > 0) {
    event.preventDefault();
  }
}

function onMouseMove(event) {
  if (!isRaycasterActive) {
    return;
  }
  isMouseMoving = true;
}

renderer.domElement.addEventListener('mouseup', onMouseUp);

function onMouseUp(event) {
  if (!isRaycasterActive) {
    return;
  }

  // Detect intersected objects
  const intersects = calculateMouseCoords(event);

  // Check if there are any intersections and no mouse movement
  if (intersects.length > 0 && !isMouseMoving) {
    const clickedMesh = intersects[0].object;
    const meshName = clickedMesh.name; // Assuming your mesh has a name property set

    // Log the clicked mesh and animation trigger to the console
   // console.log(`Clicked Mesh: ${meshName}`);

    // Trigger the animation for the clicked mesh
    animateMeshByName(meshName);
    // Show the corresponding slide
    showSlideByMeshName(meshName);
  }
}

// Initialize OrbitControls and track their activity
controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.dampingFactor = 0.075;
controls.zoomspeed = 0.3;
controls.enablePan = true;
//controls.enabled = false;

// Set minimum and maximum distance for zoom
controls.minDistance = 2.25;
controls.maxDistance = 9; 

// Flag to indicate user activity
let isUserInteracting = false;

// Event listener for OrbitControls change event (user interaction)
controls.addEventListener('change', function () {
  isUserInteracting = true;
});

// Define initial camera and mesh states
let currentCameraAngle;
let originalCameraState = {
  position: new THREE.Vector3(0, 2.50, 5),
  rotation: new THREE.Euler(-0.46, 0, 0,),
  target: new THREE.Vector3(0, 0, 0),
  angle: 0,
};

let originalMeshState = {
    position: new THREE.Vector3(0, -1, 0),
    rotation: new THREE.Euler(0, -0.6, 0, 'XYZ'),
    scale: new THREE.Vector3(1, 1, 1),
  };

				//scene.add( new THREE.CameraHelper( spotLight.shadow.camera ) );
        const ambientLight = new THREE.AmbientLight(0xffffff, 4.25)
        scene.add(ambientLight);

        const light = new THREE.DirectionalLight(0xffffff, 7.5)
        //light.intensity = 10;
        light.position.set(0.75, 8, -8);
        light.castShadow = true;
        light.shadow.mapSize.width = 512;
        light.shadow.mapSize.height = 512;
        light.shadow.camera.near = 0.1;
        light.shadow.camera.far = 100;
        light.shadow.camera.left = -10;
        light.shadow.camera.right = 10;
        light.shadow.camera.top = 10;
        light.shadow.camera.bottom = -10;
        light.shadow.radius = 20;
        light.shadow.blurSamples = 25;
        light.shadow.bias = -0.001;
        
        scene.add(light)

// Load an environment map using RGBELoader
const pmremGenerator = new THREE.PMREMGenerator( renderer );
pmremGenerator.compileEquirectangularShader();

const hdrTexture = new RGBELoader();
hdrTexture.load(hdrTexturePath, (texture) => {
  hdrTexture.mapping = THREE.EquirectangularReflectionMapping;
				  let envMap = pmremGenerator.fromEquirectangular(texture).texture;
           envMap.intensity = 1; // Adjust this value to change the brightness
				  scene.environment = envMap;
}); 

const shadowGeometry = new THREE.PlaneGeometry( 4000, 4000 );
shadowGeometry.rotateX( - Math.PI / 2 );

const shadowMaterial = new THREE.ShadowMaterial();
shadowMaterial.opacity = 0.1;
shadowMaterial.renderOrder = 1;

const shadowPlane = new THREE.Mesh( shadowGeometry, shadowMaterial );
shadowPlane.position.set(0, -1.25, 0);
shadowPlane.receiveShadow = true;
scene.add( shadowPlane );
shadowPlane.rotation.y = Math.PI / 2;

const loadModels = async (modelUrls) => {
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath('/draco/');

  const gltfLoader = new GLTFLoader();
  gltfLoader.setDRACOLoader(dracoLoader);

  const loadedModels = []; 

  for (const url of modelUrls) {
    const gltf = await new Promise((resolve, reject) => {
      gltfLoader.load(url, (gltf) => {
        resolve(gltf);
      }, undefined, (error) => {
        reject(error);
      });
    });

    loadedModels.push(gltf);
  }
  return loadedModels;
};

const modelUrls = [
  '/models/thrive9mWebOptProd.glb'
];

const loadAndAddModels = async () => {
  const loadedModels = await loadModels(modelUrls);

  loadedModels.forEach(gltf => {
   mesh = gltf.scene;
   scene.add(mesh);  

        // Log mesh names and materials
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            child.frustumCulled = false;
            //console.log('Mesh name:', child.name);
            //console.log('Materials:', child.material);
          }
   }
  );
  
  scene.traverse((child) => {
    if (child instanceof THREE.Mesh && child.name === 'ripstopBottom') {
      child.visible = true;
      child.scale.set(0.999, 0.999, 0.999);

      const material = child.material;
      if (material instanceof THREE.MeshStandardMaterial && material.name === 'ripstopBottom') {
        material.depthWrite = true;
        material.depthTest = true;
      }  
      material.needsUpdate = true;
    } else if (child instanceof THREE.Mesh && child.name === 'mainPrintInflated_1') {
      child.visible = true;
      const material = child.material;

      if (material instanceof THREE.MeshPhysicalMaterial && material.name === 'mainPrintRipstop') {
        material.depthWrite = true;
        material.depthTest = true;
      material.needsUpdate = true;
      }
    } else if (child instanceof THREE.Mesh && child.name === 'ripstopTop') {
      child.visible = true;
      child.scale.set(1.001, 1.001, 1.001);
      const material = child.material;
      if (material instanceof THREE.MeshPhysicalMaterial && material.name === 'ripstopBig') {
        material.depthWrite = false;
        material.depthTest = true;

        material.needsUpdate = true;
      }
      
    } else if (child instanceof THREE.Mesh && child.name === 'reinforcements_4') {
      const material = child.material;
      if (material instanceof THREE.MeshStandardMaterial && material.name === 'insigniaTape') {
      material.color.set(0x000000);
      material.needsUpdate = true;
      }
    } else if (child instanceof THREE.Mesh && child.name === 'reinforcements_6') {
      const material = child.material;
      if (material instanceof THREE.MeshStandardMaterial && material.name === 'proWeldLE') {
      material.color.set(0x000000);
      material.needsUpdate = true;
      }      
    }
  });

   adjustMeshScale(mesh); // Initial adjustment

    // Attach a debounced resize event listener
    const debouncedAdjustment = debounce(() => {
      adjustMeshScale(mesh);
    }, 100);

    window.addEventListener('resize', debouncedAdjustment);
  });
  rotateOnEnter();
  
  animate();
};

const adjustMeshScale = (mesh) => {
  const windowSize = window.matchMedia("(max-width: 767px)");

  if (windowSize.matches) {
    mesh.scale.set(0.75, 0.75, 0.75);
    mesh.position.set(0, 0.5, 0);
  } else {
    mesh.scale.set(1, 1, 1);
    mesh.position.set(0, 0, 0);
  }
};

// Debounce function to delay function execution
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
      timeoutId = null;
    }, delay);
  };
};

loadAndAddModels().catch(error => {
  //console.error('Error loading and adding models:', error);
});

let lastInteractionTime = Date.now();
let isFirstInteraction = true;
let IDLE_TIMEOUT = 20000; 

// Render loop
const animate = () => {
  const currentTime = Date.now();
  const deltaTime = currentTime - lastInteractionTime;

  // Change IDLE_TIMEOUT after the first interaction
  if (isFirstInteraction && deltaTime > IDLE_TIMEOUT) {
    IDLE_TIMEOUT = 750; 
    isFirstInteraction = false; // Set isFirstInteraction to false after the first interaction
  }

  if (deltaTime < IDLE_TIMEOUT) {
    // User is active, update controls and render the scene
    controls.update();
    renderer.render(scene, camera);
    delta = clock.getDelta();
  }

  // Request the next frame
  requestAnimationFrame(animate);
};

// Event listeners for user interactions (e.g., mousemove, mousedown, touchstart, touchmove)
window.addEventListener('mousemove', () => {
  lastInteractionTime = Date.now();
});

window.addEventListener('mousedown', () => {
  lastInteractionTime = Date.now();
});

window.addEventListener('touchstart', () => {
  lastInteractionTime = Date.now();
});

window.addEventListener('touchmove', () => {
  lastInteractionTime = Date.now();
});

// Start the animation loop
animate();

// Global Variables
let currentSlide = 0;

const sliderContainer = document.querySelector(".slider-container");
const slider = document.querySelector(".slider");
let mainPrintSlide = document.getElementById('mainPrint');

 // SaveSnapshot function call
 document.getElementById("saveLink").addEventListener('click', async () => {
  const meshName = 'mainPrint';

  async function animateMeshAndSaveSnapshot(meshName) {
      animateMeshByName(meshName, () => {
          // Animation is completed, now proceed with other logic
          try {
              saveSnapshot(renderer, scene, camera, mesh, originalMeshState, originalCameraState, true);
              handleResize();
          } catch (error) {
              console.error("Error occurred while saving snapshot:", error);
          }
      });
  }

  await animateMeshAndSaveSnapshot(meshName);
});

// Function to move the slider
function moveSlider(direction) {
  currentSlide += direction;
  if (currentSlide < 0) {
    currentSlide = 0;
  } else if (currentSlide > slider.children.length - 1) {
    currentSlide = slider.children.length - 1;
  }

  const slideWidth = sliderContainer.offsetWidth;
  const translateX = -currentSlide * slideWidth;
  slider.style.transform = `translateX(${translateX}px)`;

  // Get the current slide's meshName
  const currentMeshName = slider.children[currentSlide].id;

  // Call animateMeshByName with the current meshName
  animateMeshByName(currentMeshName);
}

function showSlideByMeshName(meshName) {
  const slide = document.getElementById(meshName);

  if (slide) {
    const index = Array.from(slider.children).indexOf(slide);
    moveSlider(index - currentSlide);
  }
}

// Add event listener for window resize event
window.addEventListener('resize', handleWindowResize);

// Function to handle window resize event
function handleWindowResize() {
  const slideWidth = sliderContainer.offsetWidth;
  const translateX = -currentSlide * slideWidth;
  slider.style.transform = `translateX(${translateX}px)`;
}

// Initialize slider position and handle window resize initially
handleWindowResize();

// Set currentSize to '9' initially
let currentSize = '9';
let variantId;

const sizeDropdown = document.createElement('select');
sizeDropdown.id = 'productSize';
sizeDropdown.classList.add('zindex');
const sizeContainer = document.getElementById('sizeContainer');
sizeContainer.appendChild(sizeDropdown);

const sizeOptions = [
  { value: '6', text: 'Size 6' },
  { value: '7', text: 'Size 7' },
  { value: '8', text: 'Size 8' },
  { value: '9', text: 'Size 9' },
  { value: '10', text: 'Size 10' },
  { value: '11', text: 'Size 11' },
  { value: '12', text: 'Size 12' },
];

sizeOptions.forEach(option => {
  const optionElement = document.createElement('option');
  optionElement.value = option.value;
  optionElement.textContent = option.text;
  sizeDropdown.appendChild(optionElement);
});

//const shopifyProductId = import.meta.env.VITE_SHOPIFY_PRODUCT_ID;
let variants = []; // Declare the variants variable

document.addEventListener('DOMContentLoaded', function() {
  // Function to extract and process cart data from URL parameters
  function processCartDataFromURL() {
    // Get the query parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);
    
    // Check if cart_items parameter exists
    if (urlParams.has('cart_items')) {
      // Retrieve cart item IDs from the URL parameter
      const encodedCartItemIds = urlParams.get('cart_items');
      
      // Decode and process the cart item IDs
      const cartItemIds = decodeURIComponent(encodedCartItemIds).split(',');
      
      // Now you have the cart item IDs to work with
      console.log('Received Cart Item IDs:', cartItemIds);
       // Create a cart with the received cart item IDs
    createNewCart(cartItemIds)
    .then((newCartId) => {
      // Handle the created cart ID or perform additional actions
      console.log('Cart created on Shopify:', newCartId);
      
      // For demonstration, log to the console
      console.log('Do something with the received cart item IDs...');
    })
    } else {
      // No cart information received, handle accordingly
      console.log('No cart information received');
    }
  }  
  // Call the function to process cart data from the URL when the page loads
  processCartDataFromURL();
});

// Usage 
const productId = shopifyProductId;
console.log('Product ID Main:', productId);

fetchProductVariants(productId)
  .then(data => {
    console.log('Shopify Data:', data);
    variants = data.data.product.variants.edges;

    // Populate the variation dropdown with variants
    populateVariantDropdown(variants);

    // Add event listener to the "Add to Cart" button
    const addToCartButton = document.getElementById('addToCartButton');
    addToCartButton.addEventListener('click', () => {
      addToCart(variants); // Pass the variants data to the addToCart function in shopifyApi.js
    });
  }) 
  .catch(error => {
    // Handle errors
    console.error('Error fetching product variants:', error);
  });

// Function to populate the variation dropdown with variants data
function populateVariantDropdown(variants) {
  const sizeDropdown = document.getElementById('productSize');
  const priceContainer = document.getElementById('priceContainer');

  sizeDropdown.innerHTML = ''; // Clear existing options in the dropdown

  // Populate size dropdown with merged options
  sizeOptions.forEach(option => {
    const optionElement = document.createElement('option');
    optionElement.value = option.value;

    // Find the corresponding variant for the size and set the text accordingly
    const correspondingVariant = variants.find(variant => variant.node.title === option.value);
    if (correspondingVariant) {
      optionElement.text = `${option.text} - ${correspondingVariant.node.priceV2.amount} ${correspondingVariant.node.priceV2.currencyCode}`;
    } else {
      optionElement.text = option.text;
    }

    sizeDropdown.appendChild(optionElement);
  });

   // Set initial chosen product variant to size '9'
   sizeDropdown.value = '9';

   // Trigger change event to update priceContainer based on the initial selection
   const selectedSize = sizeDropdown.value;
   const correspondingVariant = variants.find(variant => variant.node.title === selectedSize);
   
   if (correspondingVariant) {
     const price = `${correspondingVariant.node.priceV2.amount} ${correspondingVariant.node.priceV2.currencyCode}`;
     priceContainer.textContent = `Price: ${price}`;
   } else {
     // Handle the case where no corresponding variant is found for the selected size
     priceContainer.textContent = 'Price: N/A';
   }

  // Event listener for variant selection
  sizeDropdown.addEventListener('change', () => {
    const selectedSize = sizeDropdown.value;
    const correspondingVariant = variants.find(variant => variant.node.title === selectedSize);

    if (correspondingVariant) {
      const price = `${correspondingVariant.node.priceV2.amount} ${correspondingVariant.node.priceV2.currencyCode}`;
      priceContainer.textContent = `Price: ${price}`;
    } else {
      // Handle the case where no corresponding variant is found for the selected size
      priceContainer.textContent = 'Price: N/A';
    }
  });
}

// Drawer Trigger Logic
const cartDrawerContainer = document.querySelector('.cart-drawer');
const cartDrawerButton = document.getElementById('cartDrawerButton');

// Function to open the cart drawer
function openCartDrawer() {
  cartDrawerContainer.classList.add('open');
}

// Function to close the cart drawer
function closeCartDrawer() {
  cartDrawerContainer.classList.remove('open');
}

// Event listener for opening the cart drawer when the button is clicked
cartDrawerButton.addEventListener('click', () => {
  if (cartDrawerContainer.classList.contains('open')) {
    // If drawer is open, close it
    closeCartDrawer();
  } else {
    // If drawer is closed, open it
    openCartDrawer();
  }
});

// Additional event listener to close the drawer when clicking outside the drawer
window.addEventListener('click', (event) => {
  if (cartDrawerContainer.classList.contains('open') && !cartDrawerContainer.contains(event.target) && event.target !== cartDrawerButton) {
    // If drawer is open and user clicks outside the drawer or button, close it
    closeCartDrawer();
  }
});

// Event listener for "View Cart" button click
const viewCartButton = document.getElementById('viewCartButton');
viewCartButton.addEventListener('click', async () => {
  const cartItems = await fetchCartData();
  // Update the UI to display cartItems in the cart summary
  updateCartSummaryUI(cartItems);
  // Show the cart summary UI (you need to implement this function)
  showCartSummary();
});

const swatchElements = {};

function initializeSwatches() {
  customizationOptions.forEach(option => {
    const meshName = option.meshName;
    const swatches = createSwatches(meshName, currentSize);
    swatchElements[meshName] = swatches;

    // Call initializeColorPicker within this scope
    const slide = document.getElementById(meshName);
    const customization = option; 

    //initializeColorPicker(meshName, customization, slide);
  });
}

initializeSwatches();

 // Event listener for the size dropdown
 sizeDropdown.addEventListener('change', function () {
  //console.log(`Size dropdown changed. Selected size: ${sizeDropdown.value}`);
  const selectedSize = sizeDropdown.value;
  currentSize = selectedSize;
  handleSizeChange();  
  //console.log('Selected Size Dropdown value:', currentSize);
});

// Handle size dropdown change
function handleSizeChange() {
  currentSize = sizeDropdown.value;
  updateSwatches();
  applySizeTextureChanges();
  updateSizeInModal(currentSize); // Your existing function to update size in the modal
}

function updateSwatches() {
  const slides = document.querySelectorAll('.slider'); // Select all slides within the .slider container
  slides.forEach(slide => {
      // Select and remove specific elements within the slide
      const elementsToRemove = slide.querySelectorAll('.swatch-row, .swatchContainer, .undefined-saturation-container');
      elementsToRemove.forEach(element => {
          element.remove(); // Remove the elements from the DOM
      });

      const selectedSize = sizeDropdown.value;
      customizationOptions.forEach(option => createSwatches(option.meshName, selectedSize));
  });
}

function createSwatches(meshName, selectedSize) {
  
  const swatches = [];

  const customization = customizationOptions.find(option => option.meshName === meshName);

  if (!customization) {
    console.error(`No customization option found for mesh ${meshName}.`);
    return;
  }
  // Get the container div for this slide using the meshName as the ID
  const slideContainer = document.getElementById(meshName);

  if (!slideContainer) {
    console.error(`Slide container with id '${meshName}' not found.`);
    return;
  }

  // Create a slide for the swatches
  const slide = document.createElement('div');
  slide.className = 'swatchContainer';

  // Declare textureRow outside of the if-else blocks
  let textureRow = document.createElement('div');
  textureRow.className = 'swatch-row';

  if (!customization) {
    console.error(`No customization option found for mesh ${meshName}.`);
    return swatches;
  }

if (meshName === 'customLogo') {
  // Create parent container for file input
  const inputContainer = document.createElement('div');
  inputContainer.className = 'inputContainer';

  // Create label element
  const label = document.createElement('label');
  label.textContent = 'Choose Image';
  label.htmlFor = 'imageInput';
  label.className = 'custom-file-input-label';

  // Create file input element
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.id = 'imageInput';
  fileInput.className = 'custom-file-input';
  fileInput.accept = 'image/*';

  const resetButton = document.createElement('button');
  resetButton.id = 'resetButton';
  resetButton.textContent = 'Reset';

  resetButton.addEventListener('click', () => {
    resetMesh(meshName); // Reset the mesh to its initial state
  });

  // Simplified event listener for file input change
  fileInput.addEventListener('change', (event) => {
    const file = event.target.files[0];
    if (!file) {
      return; // Exit if no file is selected
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const texture = new THREE.TextureLoader().load(e.target.result);
      texture.flipY = false; // Adjust if necessary for your texture coordinates
      texture.colorSpace = THREE.SRGBColorSpace;
      updateMeshTexture(texture); // Apply the texture to both meshes and materials
    };
    reader.readAsDataURL(file); // Read the file as a Data URL
  });

  // Append file input and reset button to the inputContainer
  inputContainer.appendChild(label);
  inputContainer.appendChild(fileInput);
  inputContainer.appendChild(resetButton);
  textureRow.appendChild(inputContainer); // Assuming 'textureRow' is your target container
}

// Update the texture for both materials of both meshes
function updateMeshTexture(texture) {
  // Get the two meshes by their names
  const mainPrintInflated = scene.getObjectByName('mainPrintInflated');
  const mainPrintInflated_1 = scene.getObjectByName('mainPrintInflated_1');

  if (mainPrintInflated && mainPrintInflated.material) {
    // Apply the new texture to the 'mainPrintDacron' material of 'mainPrintInflated'
    mainPrintInflated.material.map = texture;
    mainPrintInflated.material.needsUpdate = true;
  }

  if (mainPrintInflated_1 && mainPrintInflated_1.material) {
    // Apply the new texture to the 'mainPrintRipstop' material of 'mainPrintInflated_1'
    mainPrintInflated_1.material.map = texture;
    mainPrintInflated_1.material.needsUpdate = true;
  }
}

// Reset both meshes to their initial states
function resetMesh(meshName) {
  // Reset mesh 'mainPrintInflated'
  const mainPrintInflated = scene.getObjectByName('mainPrintInflated');
  if (mainPrintInflated && mainPrintInflated.material) {
    mainPrintInflated.material.opacity = 0;
    mainPrintInflated.material.transparent = true;
    mainPrintInflated.material.needsUpdate = true;
  }

  // Reset mesh 'mainPrintInflated_1'
  const mainPrintInflated_1 = scene.getObjectByName('mainPrintInflated_1');
  if (mainPrintInflated_1 && mainPrintInflated_1.material) {
    mainPrintInflated_1.material.opacity = 0;
    mainPrintInflated_1.material.transparent = true;
    mainPrintInflated_1.material.needsUpdate = true;
  }
}

// Create an object to store the color swatch elements for each customization option
const colorSwatchContainers = {};

const optionMenu = document.getElementById('optionMenu');

// Append the texture and color rows to the slide
slide.appendChild(textureRow);

// Append the slide to the slideContainer
slideContainer.appendChild(slide);

return swatches;
}

// Animations
let currentCamera = new THREE.Vector3();
let currentCameraTarget = new THREE.Vector3();
currentCameraAngle = 0; // Initialize currentCameraAngle

// Initialize GSAP timeline
const timeline = new gsap.timeline({
  onUpdate: function () {
    controls.update();
  },
  onComplete: () => {

    controls.enabled = true; // Re-enable OrbitControls
  },
});

// Function to animate a mesh by its name
function animateMeshByName(meshName, onAnimationComplete) {

let originalCameraAngle = 0; // Initial camera angle
let currentCamera = new THREE.Vector3();
let currentCameraRotation = new THREE.Euler();
let currentCameraTarget = new THREE.Vector3();

let targetState;

let yOffset = 0.65; // Calculate the offset based on the difference in Y-axis positions
if (window.innerWidth <= 768) {
    yOffset = 0.75; // Set a different offset value for mobile screens if needed
} 

  switch (meshName) {

    case 'mainPrint':
      //console.log('Animating mainPrint mesh');
      targetState = {
      // Define the target positions, rotations, and angles to return to the original state
      position: new THREE.Vector3(1.36, 2.00, 4.81),
      rotation: new THREE.Euler(-0.39, 0.25, 0.10, 'XYZ'),
      angle: 0,
      target: new THREE.Vector3(0, 0, 0),
      };

      break;

      case 'sizeLE':
       // console.log('Animating sizeLE mesh');
         targetState = {
        // Define the target positions, rotations, and angles
        position: new THREE.Vector3(-1.26, 1.67, 2.60),
        rotation: new THREE.Euler(-0.05, -0.46, -0.02, 'XYZ'),
        angle: 1.1422007126452234,
        target: new THREE.Vector3(0.14, 1.52, -0.24),
        }
      
        break;

        // sizeCanopy Mesh
case 'sizeCanopy':
  //console.log('Animating sizeCanopy mesh');

  targetState = {
    position: new THREE.Vector3(-2.86, 1.47, -1.11,),
    rotation: new THREE.Euler(-2.54, -1.19, -2.57, 'XYZ'),
    angle: 0,
    target: new THREE.Vector3(0.10, 0.82, -0.16 ),
  }

  break;

// Force Mesh
case 'force':
  //console.log('Animating force mesh');
  targetState = {

    position: new THREE.Vector3(-2.73, 0.81, -1.09,),
    rotation: new THREE.Euler(-2.41, -1.20, -2.44, 'XYZ'),
    angle: 0, // Adjust as needed
    target: new THREE.Vector3(-0.00, 0.12, -0.31,),
  }

  break;

// Harlem Left LE Mesh
case 'harlemLeftLE':
  //console.log('Animating harlemLeftLE mesh');
  targetState = {
    position: new THREE.Vector3(-2.63, 0.94, 0.68),
    rotation: new THREE.Euler(-0.04, -0.82, -0.03, 'XYZ'),
    angle: 0, // Adjust as needed
    target: new THREE.Vector3( -0.73, 0.86, -1.06),
  }
  
  break;

// harlemRightLE Mesh
case 'harlemRightLE':
  //console.log('Animating harlemRightLE mesh');
  targetState = {
    position: new THREE.Vector3(-0.09, 1.03, 2.90),
    rotation: new THREE.Euler(-0.24, -0.31, -0.07),
    angle: 0,
    target: new THREE.Vector3( 0.85, 0.33, 0.11),
  }

  break;

// customText Mesh
case 'customText':
 // console.log('Animating customText mesh');
  targetState = {
    position: new THREE.Vector3(-2.26, 1.22, -1.15),
    rotation: new THREE.Euler( -2.93, -0.87, -2.98),
    angle: 0,
    target: new THREE.Vector3(0.30, 0.79, 0.94),  
  }

  break;

// customLogo Mesh
case 'customLogo':
  //console.log('Animating customLogo mesh');
  targetState = {
    position: new THREE.Vector3(2.05, 0.77, 1.68,),
    rotation: new THREE.Euler(-0.21, 1.04, 0.18),
    angle: 0, // Adjust as needed
    target: new THREE.Vector3(0.10, 0.53, 0.58,),
  }

  break;
      
    default:
  }
  // Check if the current camera position, angle, and target are different from the target values
  if (
    !camera.position.equals(targetState.position) ||
    !camera.rotation.equals(targetState.rotation) ||
    !controls.target.equals(targetState.target)
  ) {
    timeline.eventCallback('onStart', () => {
      controls.enabled = false;
    });

    // Animate directly to the target state without animating back to original state
    timeline.clear();
    timeline.to(camera.position, { duration: 1, x: targetState.position.x, y: targetState.position.y, z: targetState.position.z });
    timeline.to(camera.rotation, { duration: 1, x: targetState.rotation.x, y: targetState.rotation.y, z: targetState.rotation.z }, "-=1");
    timeline.to(controls.target, { duration: 1, x: targetState.target.x, y: targetState.target.y, z: targetState.target.z }, "-=1");

    // Update the camera's lookAt property
    camera.lookAt(targetState.target);

    // Start the GSAP timeline
    timeline.play().then(() => {
      // Animation is completed, execute the callback function
      if (onAnimationComplete && typeof onAnimationComplete === 'function') {
          onAnimationComplete();
      }
  });
}
}

//preloader = document.getElementById('preloader');
const loadingText = document.getElementById('loading-text');
const sceneContainer = document.querySelector('.scene-container');
const mainInterface = document.getElementById('mainInterface');

/* Rotate Scene on Enter */
function rotateOnEnter() {
  //controls.enabled = false;
  
  const enterScene = scene.getObjectByName("mesh");
  let tl = gsap.timeline({ paused: true })
  .pause()
  .eventCallback("onStart", () => {
    controls.enabled = false;
  })
  
  .to(loadingText, {autoAlpha: 0, duration: 2.5}, 0)
 // .to('#loading-text', { opacity: 0, duration: 1.5,}, 2)
  .to(introLogo, { autoAlpha: 1, duration: 2, ease: 'power3.out'}, 2.25)
  .fromTo(introLogo, {scaleX: 0, scaleY: 0}, { duration: 1.5, scaleX: 0.2, scaleY: 0.2, transformOrigin: 'center center', ease: 'back.out(2.0)'}, 2)
  .to(introLogo, { duration: 1.05, autoAlpha: 0, onComplete: () => {
    // Set the visibility to "none" after the animation is complete
    preloader.style.display = 'none';
  }}, 4.5 )
  .to(sceneContainer, { duration: 1, autoAlpha: 1 }, 6.25 )
  .to(mainInterface, { duration: 2, autoAlpha: 1 }, 5.5 )
  .fromTo( camera.position, { x:0, y: 5, z: -2.75 },
     { duration: 4, x: 0, y: 2.5, z: 5, ease: 'back.out(5)', }, 5)
   // Trigger the animation on #mainInterface to expand it
  //.from("#mainInterface", { scaleY: 0, duration: 0.3 }, 4.5)
  .to('.harlemLogo', {autoAlpha: 1, duration: 0.5}, 9 )
  .fromTo( mesh.rotation, { x: 0, y: -3.5, z: 0 }, { duration: 4, x: 0, y: -0.6, z: 0, ease: 'back.out(4.5)',
       onUpdate: function () {
        controls.update(); 
      },
      onComplete: function () {
          showPopup(0); 
            controls.enabled = true;    
     },
    },
    5.25
  )
  .invalidate().play(0);
}

// Function to toggle the modal visibility
function toggleModal(show = null) {
  const modal = document.getElementById('optionModal');
  if (show !== null) {
    if (show) {
      modal.classList.add('show');
      const meshName = 'mainPrint'; // Set the meshName to 'mainPrint'
  animateMeshByName(meshName);
      setTimeout(() => {
      saveSnapshot(renderer, scene, camera, mesh, originalMeshState, originalCameraState, false); // Call saveSnapshot when the modal opens
      //restoreInitialState(camera, mesh, originalCameraState, originalMeshState);
      // handleResize();
      }, 1000);
    } else {
      modal.classList.remove('show');
      handleResize();
    }
  } else {
    modal.classList.toggle('show'); // Toggle the 'show' class
  }
}

const overviewButton = document.getElementById('overview');

overviewButton.addEventListener('click', () => {
  toggleModal(true);
  //saveSnapshot(renderer, scene, camera, mesh, initialCamPos, false); // Save to device
});

// Event listener for the close button inside the modal
const closeModalButton = document.getElementById('closeModal');
closeModalButton.addEventListener('click', () => toggleModal(false));

const menuButton = document.getElementById('menuCollapse');
const topMenuContainer = document.querySelector('.top-menu-container');
const navigationContainer = document.querySelector('.navigation-container');
//const sizeContainer = document.getElementById('sizeContainer');
const saveLink = document.getElementById('saveLink');
const overview = document.getElementById('overview');

let isMenuCollapsed = false;

function updateTopMenuPosition() {
  let topMenuPosition;
  let navigationContainerPosition;
  let mainInterfacePosition;
  let elementVisibility;
  let elementOpacity;
  let slideHeadlinesPadding;
  let menuCollapseContainerTop;
  
   if (isMenuCollapsed && window.innerWidth <= 767) {
    slideHeadlinesPadding = '0em';
  } else if (!isMenuCollapsed && window.innerWidth <= 767) {
    slideHeadlinesPadding = '1.4em';
  } else if (isMenuCollapsed & window.innerWidth >= 767) {
    slideHeadlinesPadding = '0.25em';
  } else if (!isMenuCollapsed && window.innerWidth >= 767) {
    slideHeadlinesPadding = '0'
  }

  // Apply padding-top to .slideHeadlines
  const slideHeadlines = document.querySelectorAll('.slideHeadlines');
  slideHeadlines.forEach(slide => {
    slide.style.paddingTop = slideHeadlinesPadding;
  });

  if (window.innerWidth <= 767) {
    topMenuPosition = isMenuCollapsed ? 16 : 46;
    navigationContainerPosition = isMenuCollapsed ? 5 : 32;
    mainInterfacePosition = isMenuCollapsed ? -15 : 2.5;
    elementVisibility = isMenuCollapsed ? 'hidden' : 'visible';
    elementOpacity = isMenuCollapsed ? 0 : 1;
    } else {
    topMenuPosition = isMenuCollapsed ? 8.75 : 15;
    navigationContainerPosition = isMenuCollapsed ? 2.5 : 22;
    mainInterfacePosition = isMenuCollapsed ? -19 : 0;
    elementVisibility = 'visible';
    elementOpacity = 1;
    }
  
  topMenuContainer.style.bottom = `${topMenuPosition}vh`;
  navigationContainer.style.bottom = `${navigationContainerPosition}vh`;
  mainInterface.style.bottom = `${mainInterfacePosition}vh`;
  sizeContainer.style.visibility = elementVisibility;
  sizeContainer.style.opacity = elementOpacity;
  saveLink.style.visibility = elementVisibility;
  saveLink.style.opacity = elementOpacity;
  overview.style.visibility = elementVisibility;
  overview.style.opacity = elementOpacity;
  // Apply top position to #menuCollapseContainer
  const menuCollapseContainer = document.getElementById('menuCollapseContainer');
  menuCollapseContainer.style.top = menuCollapseContainerTop;
}

menuButton.addEventListener('click', () => {
  isMenuCollapsed = !isMenuCollapsed;
  menuButton.classList.toggle('rotated', isMenuCollapsed);
  updateTopMenuPosition();

});

window.addEventListener('resize', updateTopMenuPosition);

// App Tutorial

const popupContainer = document.querySelector('.popup-container');
const acceptCheckbox = document.getElementById('acceptCheckbox');
const skipButton = document.getElementById('skipButton');
const nextButton = document.getElementById('nextButton');

const popups = document.querySelectorAll('.popup');
const popupButtons = {
  'checkboxPopup': {
    next: document.getElementById('navigationNext'),
    skip: document.getElementById('navigationSkip')
  },
  'navigationPopup': {
    prev: document.getElementById('navigationPrev'),
    next: document.getElementById('navigationNext'),
    skip: document.getElementById('navigationSkip')
  },
  'sizePopup': {
    prev: document.getElementById('sizePrev'),
    next: document.getElementById('sizeNext'),
    skip: document.getElementById('sizeSkip')
  },
  'overviewPopup': {
    prev: document.getElementById('overviewPrev'),
    next: document.getElementById('overviewNext'),
    skip: document.getElementById('overviewSkip')
  },
  'previewPopup': {
    prev: document.getElementById('startPrev'),
    skip: document.getElementById('startSkip'),
    next: document.getElementById('startButton')
  }
};

let currentPopupIndex = 0;

function flashElement(elementId) {
  const element = document.getElementById(elementId);
  const originalZIndex = element.style.zIndex; // Store the original z-index value

  element.style.zIndex = 9999; // Set a higher z-index value while animating

  let count = 0;
  const maxCount = 2; // Number of times the element will flash

  function toggleOpacity() {
    const currentOpacity = parseFloat(window.getComputedStyle(element).opacity);
    const newOpacity = currentOpacity === 0 ? 1 : 0; // Toggle opacity between 0 and 1
    element.style.opacity = newOpacity;
    count++;

    if (count >= maxCount * 3) {
      // Stop the animation after maxCount flashes
      element.style.opacity = 1;
      element.style.zIndex = originalZIndex; // Set back to original z-index value
      return;
    }

    setTimeout(toggleOpacity, 750); // Toggle opacity every 750 milliseconds (0.75 seconds)
  }

  toggleOpacity();
}

function showPopup(index) {
  popupContainer.style.display = 'flex';
  popups.forEach((popup, i) => {
    if (i === index) {
      popup.style.display = 'block';
      addPopupButtonListeners();
      if (popup.id === 'sizePopup') {
        flashElement('sizeContainer');
      } else if (popup.id === 'navigationPopup') {
        flashElement('navigationContainer');
      } else if (popup.id === 'overviewPopup') {
        flashElement('snapshotContainer');
      } else if (popup.id === 'previewPopup') {
        flashElement('fullscreenBtn'); 
      }
    } else {
      popup.style.display = 'none';
    }
  });
  currentPopupIndex = index;
}

function hidePopup() {
  popupContainer.style.display = 'none';
  popups.forEach((popup) => {
    popup.style.display = 'none';
  });
}

function handleCheckboxChange() {
  const isCheckboxChecked = acceptCheckbox.checked;
  const currentPopupButtons = popupButtons[popups[currentPopupIndex].id];
  
  nextButton.disabled = !isCheckboxChecked || !currentPopupButtons.next;
  skipButton.disabled = !isCheckboxChecked || !currentPopupButtons.skip;
}

function handlePrevClick() {
  const currentPopupButtons = popupButtons[popups[currentPopupIndex].id];
  if (currentPopupIndex > 0 && currentPopupButtons.prev) {
    showPopup(currentPopupIndex - 1);
    addPopupButtonListeners();
  }
}

function handleNextClick() {
  const currentPopupButtons = popupButtons[popups[currentPopupIndex].id];
  if (acceptCheckbox.checked && currentPopupButtons.next) {
    if (currentPopupIndex + 1 < popups.length) {
      showPopup(currentPopupIndex + 1);
      addPopupButtonListeners();
    } else {
      hidePopup();
      
      // Remove event listeners for next, prev, and skip buttons
      if (currentPopupButtons.prev) {
        currentPopupButtons.prev.removeEventListener('click', handlePrevClick);
      }
      if (currentPopupButtons.next) {
        currentPopupButtons.next.removeEventListener('click', handleNextClick);
      }
      if (currentPopupButtons.skip) {
        currentPopupButtons.skip.removeEventListener('click', handleSkipClick);
      }
    }
  }
}

function handleSkipClick() {
  if (acceptCheckbox.checked) {
    hidePopup();
  }
}

function addPopupButtonListeners() {
  const currentPopupButtons = popupButtons[popups[currentPopupIndex].id];

  if (currentPopupButtons.prev) {
    currentPopupButtons.prev.addEventListener('click', handlePrevClick);
  }

  if (currentPopupButtons.next) {
    currentPopupButtons.next.addEventListener('click', handleNextClick);
  }

  if (currentPopupButtons.skip) {
    currentPopupButtons.skip.addEventListener('click', handleSkipClick);
  }
}

// Event listeners for the previous button
if (popupButtons[popups[currentPopupIndex].id].prev) {
  popupButtons[popups[currentPopupIndex].id].prev.addEventListener('click', handlePrevClick);
}

function initPopupListeners() {
  // Event listeners for the first popup
  acceptCheckbox.addEventListener('change', handleCheckboxChange);
  skipButton.addEventListener('click', handleSkipClick);
  nextButton.addEventListener('click', handleNextClick);

  // Initialize button listeners for the first popup
  addPopupButtonListeners();
}

// Call this function to initialize listeners when the script loads
initPopupListeners();

console.log('------------------------------------------');
  console.log('  Application created by PIXELPORN INDUSTRIES   ');
  console.log('  Website: https://pixelpornindustries.com ');
  console.log('  Version: 1.2.0                          ');
  console.log('------------------------------------------');
