// shopifyClientApi.js
import html2canvas from 'html2canvas';

// Client-side functions to make API requests to the server and update the UI accordingly
export async function fetchShopifyData() {
    try {
      const response = await fetch('/api/shopify/fetchShopifyData');
      
      console.log('Response Status fetchShopifyData:', response.status);
  
      let data;
      try {
        data = await response.json();
        console.log('Response JSON:', data);
      } catch (jsonError) {
        // Handle JSON parsing error if necessary
        console.error('Error parsing JSON:', jsonError);
      }
  
      if (response.ok) {
        return data; // Return the parsed JSON data
      } else {
        console.error('Error fetching Shopify data:', response.statusText);
        throw new Error('Error fetching Shopify data. Please try again later.');
      }
    } catch (error) {
      console.error('Error fetching Shopify data:', error);
      throw error;
    }
  }  

export async function fetchProductVariants(productId) {
    console.log('Product ID Client:', productId);

    try {
      const response = await fetch(`/api/shopify/fetchProductVariants?productId=${productId}`);
      console.log('Response Status fetchProductVariants:', response.status);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const variantsData = await response.json();
      return variantsData;
    } catch (error) {
      console.error('Error fetching product variants:', error);
      throw error;
    }
  }

  export async function fetchCartData() {
    const checkoutId = localStorage.getItem('checkoutId');
    const apiUrl = '/api/shopify/fetchCartData';

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ checkoutId }),
        });

        const responseData = await response.json();
        console.log('Raw API Response fetchCartData:', responseData);
    
        if (responseData.data && responseData.data.node && responseData.data.node.lineItems) {
          const lineItems = responseData.data.node.lineItems.edges;
          console.log('Line Items:', lineItems);
        }

        if (responseData.checkout) {
            // Update the checkout URL in localStorage and UI if available
            localStorage.setItem('checkoutUrl', responseData.checkout.webUrl);
            console.log('Updated Checkout URL FetchCartData:', responseData.checkout.webUrl);
            updateCheckoutButtonUrl(responseData.checkout.webUrl);
        }
        //const extractedCartItems = responseData.data.node.lineItems.edges;
        //console.log('Extracted Cart Items:', extractedCartItems);
        return responseData;
    } catch (error) {
        console.error('Error fetching cart data:', error);
        throw error;
    }
}

export async function createSimpleCart(cartItems) {

    // Check if the cart was already created
    if (localStorage.getItem('cartCreated') === 'true') {
      console.log('Cart already created. Skipping cart item addition.');
      return;
    }
  

  let checkoutId = localStorage.getItem('checkoutId');
  let checkoutUrl = localStorage.getItem('checkoutUrl');
  console.log('Stored Checkout ID createSimpleCart:', checkoutId);
console.log('Stored Checkout URL createSimpleCart:', checkoutUrl);

  if (!checkoutId) {
    try {
      const response = await fetch('/api/shopify/createSimpleCheckout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      const data = await response.json();
      console.log('Response Status createSimpleCart:', data.status);

      console.log('API Response createSimpleCart:', data); // Add this line to log the entire response

      if (!response.ok || data.errors) {
        console.error('Error creating simple checkout:', data.errors || response.statusText);
        throw new Error('Error creating simple checkout');
      }

      if (data && data.data && data.data.checkoutCreate && data.data.checkoutCreate.checkout) {
        checkoutId = data.data.checkoutCreate.checkout.id;
        checkoutUrl = data.data.checkoutCreate.checkout.webUrl;
        localStorage.setItem('checkoutId', checkoutId);
        localStorage.setItem('checkoutUrl', checkoutUrl);
        console.log('Stored Checkout ID and URL createSimpleCart:', checkoutId, checkoutUrl);
      } else {
        console.error('Unexpected response format:', data);
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error creating new simple checkout ID:', error);
      throw error;
    }
  }
  
    // Add items to the existing or new cart
    for (const item of cartItems) {
      try {
        await addItemToCart(checkoutId, item.id, item.quantity);
        console.log(`Added item to cart: ${item.id}`);
      } catch (error) {
        console.error(`Error adding item ${item.id} to cart:`, error);
      }
    }
  
    console.log('All items added to simple cart');
  
    // Fetch cart data after adding items to the cart
    const cartData = await fetchCartData();
    localStorage.setItem('cartData', JSON.stringify(cartData));
    updateCartDrawer(cartData);

      // Set the flag indicating the cart has been created
  localStorage.setItem('cartCreated', 'true');
  }

  export async function createCheckout(variantId, screenshotUrl, quantity = 1) {
localStorage.setItem('checkoutId', data.data.checkoutCreate.checkout.id);
localStorage.setItem('checkoutUrl', data.data.checkoutCreate.checkout.webUrl);
console.log('Stored Checkout ID createCheckout:', data.data.checkoutCreate.checkout.id);
console.log('Stored Checkout URL createCheckout:', data.data.checkoutCreate.checkout.webUrl);
  
    // Use existing checkout if available
    if (checkoutId && checkoutUrl) {
      try {
        await addItemToCart(checkoutId, variantId, quantity);
        console.log(`Added item to existing cart createCheckout: ${variantId}`);
        return { checkoutId, checkoutUrl }; // Return existing checkout details
      } catch (error) {
        console.error(`Error adding item ${variantId} to existing cart:`, error);
        throw error;
      }
    }
  
    // Prepare the request body with mandatory fields
    const requestBody = { variantId, quantity };
  
    // Add screenshotUrl to the request body if it's provided
    if (screenshotUrl) {
      requestBody.screenshotUrl = screenshotUrl;
    }
  
    // Make the POST request
    const response = await fetch('/api/shopify/createCheckout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
  
    // Handle response and errors
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`Error creating checkout: ${errorMessage}`);
    }
  
    const data = await response.json();
    console.log('Response Status CreateCheckout:', data.status);

    if (data.errors) {
      console.error('GraphQL Errors:', data.errors);
      throw new Error('GraphQL Errors occurred');
    }
  
    // Log the API response to inspect its structure
    console.log('API Response createCheckout:', data);
  
    // Store the new checkoutId in localStorage
    if (data.data && data.data.checkoutCreate && data.data.checkoutCreate.checkout) {
      localStorage.setItem('checkoutId', data.data.checkoutCreate.checkout.id);
      localStorage.setItem('checkoutUrl', data.data.checkoutCreate.checkout.webUrl); // store checkout URL
      console.log('Stored Checkout URL createCheckout2:', localStorage.getItem('checkoutUrl'));
    }
  
    return data;
  }

export async function addItemToCart(checkoutId, variantId, quantity = 1) {
  console.log('Checkout Id addItemToCart:', checkoutId);

  // Retrieve the checkout URL from localStorage
  const checkoutUrl = localStorage.getItem('checkoutUrl');
  console.log('Item Added to Checkout URL addItemToCart:', checkoutUrl); // Log the checkout URL

  const apiUrl = '/api/shopify/addItemToCart'; // Assuming your server is running on the same domain

  const body = JSON.stringify({ checkoutId, variantId, quantity });
  console.log('Request Body addItemToCart:', body);

  try {
      const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: body,
      });

      const data = await response.json();
      console.log('Response from server addItemToCart:', data);

      // Store the variantId in local storage with the key 'selectedVariantId'
      localStorage.setItem('selectedVariantId', variantId);
  } catch (error) {
      console.error('Error adding item to cart:', error);
      throw error;
  }
}

export async function addToCart(variants, variantId, quantity) {
  console.log('Variants received:', variants);

  try {
      const selectedSize = document.getElementById('productSize').value;
      const correspondingVariant = variants.find(variant => variant.node.title === selectedSize);

      if (!correspondingVariant) {
          console.error('Error: No variant found for the selected size.');
          showErrorToUser('No variant found for the selected size.');
          return;
      }

      const selectedVariantId = correspondingVariant.node.id;
      const optionModal = document.getElementById('optionModal');

      // Capture screenshot and upload image sequentially
      const screenshotUrl = await captureScreenshot(optionModal);
      console.log('Generated screenshot URL:', screenshotUrl);

      // Upload the screenshot to AWS S3
      const uploadedImageUrl = await uploadImageToS3(screenshotUrl);

      let checkoutId = localStorage.getItem('checkoutId');
      let checkoutUrl = localStorage.getItem('checkoutUrl');

      if (!checkoutId || !checkoutUrl) {
          const checkoutData = await createCheckout(selectedVariantId, screenshotUrl, quantity);
          checkoutId = checkoutData.data.checkoutCreate.checkout.id;
          checkoutUrl = checkoutData.data.checkoutCreate.checkout.webUrl;
          localStorage.setItem('checkoutId', checkoutId);
          localStorage.setItem('checkoutUrl', checkoutUrl);
      } else {
          await addItemToCart(checkoutId, selectedVariantId, quantity);
          // Fetch updated cart data and update the checkout URL in UI
          const updatedCartData = await fetchCartData();
          localStorage.setItem('cartData', JSON.stringify(updatedCartData));
          console.log('Updated cart data stored in localStorage addToCart:', updatedCartData);

          // Optionally, update cart drawer with updatedCartData
          updateCartDrawer(updatedCartData);
      }
  } catch (error) {
      console.error('Error adding item to cart:', error);
      showErrorToUser('Error adding item to cart. Please try again later.');
  }
}

// Function to update the cart drawer UI
export async function updateCartDrawer() {
  const checkoutId = localStorage.getItem('checkoutId');

  try {
    const response = await fetch('/api/shopify/fetchCartData', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ checkoutId }),
    });

    const responseData = await response.json();
    const cartItems = responseData.map(item => item.node);
    console.log('Cart Items Before Passing to updateCartDrawerUI:', cartItems);
    updateCartDrawerUI(cartItems); // Update the cart drawer UI with the latest cart data
  } catch (error) {
    console.error('Error fetching updated cart data:', error);
  }
}

function updateCartDrawerUI(cartItems) {
  let isNoteVisible = false; // Add this flag at the beginning of the function
  console.log('Received Cart Items in updateCartDrawerUI:', cartItems);
  const cartDrawer = document.getElementById('cartDrawer');

  const cartBtnContainer = document.getElementById('buttonRow');
  const bottomContainer = document.getElementById('bottomContainer');

  // Create or update the checkout button
  let checkoutButton = document.getElementById('proceedToCheckoutBtn');
  let isButtonNew = false; // Flag to track if the button is newly created
  if (!checkoutButton) {
      checkoutButton = document.createElement('button');
      checkoutButton.id = 'proceedToCheckoutBtn';
      checkoutButton.className = 'cart-button';
      checkoutButton.textContent = 'Checkout';
      cartBtnContainer.appendChild(checkoutButton);
      isButtonNew = true;
  }

  // Set the checkout button's href attribute
  const checkoutUrl = localStorage.getItem('checkoutUrl');
  if (checkoutUrl) {
      checkoutButton.href = checkoutUrl;
      checkoutButton.disabled = false;
  } else {
      console.error('Checkout URL not found');
      checkoutButton.disabled = true;
  }

  // Add event listener to checkout button only if it's newly created
  if (isButtonNew) {
      checkoutButton.addEventListener('click', () => {
          if (checkoutUrl) {
              window.location.href = checkoutUrl;
          } else {
              console.error('Checkout URL not found');
              event.preventDefault();
          }
      });
  }

  const cartContainer = document.getElementById('cartContainer');
  
  // Clear existing cart items
  cartContainer.innerHTML = '';

  let totalPrice = 0; // Initialize totalPrice variable

  // Iterate over cart items to update the UI
  cartItems.forEach(item => {
    console.log('Processing Item in updateCartDrawerUI:', item);
    console.log('Accessing item.variant:', item.variant);
    if (!item || !item.variant) {
      console.error('Item or Variant is undefined:', item);
      return; // Skip this item and continue with the next one
    }

            // Calculate total price
            const itemTotal = item.quantity * parseFloat(item.variant.priceV2.amount);
            totalPrice += itemTotal;

    console.log('Item Variant:', item.variant); // Log the variant object
    const cartItemElement = document.createElement('div');
    cartItemElement.className = 'cart-item';

    // Thumbnail
    const thumbnailSrc = item.variant.image ? item.variant.image.originalSrc : '';
    const thumbnailAlt = item.variant.image ? item.variant.image.altText : '';
    const thumbnail = document.createElement('img');
    thumbnail.src = thumbnailSrc;
    thumbnail.alt = thumbnailAlt;
    thumbnail.className = 'thumbnail';
    cartItemElement.appendChild(thumbnail);

    // Product details div
    const detailsDiv = document.createElement('div');
    detailsDiv.className = 'details';

    const titlePriceSizeDiv = document.createElement('div');
    titlePriceSizeDiv.className = 'titlePriceSize';

    // Product title
    const titleElement = document.createElement('div');
    titleElement.textContent = `${item.title}`;
    titleElement.className = 'title';
    titlePriceSizeDiv.appendChild(titleElement);

    // Price information
    const priceElement = document.createElement('div');
    const price = item.variant.priceV2.amount;
    //const currencyCode = item.variant.priceV2.currencyCode;
    priceElement.textContent = `€ ${price}0`;
    priceElement.className = 'price';
    titlePriceSizeDiv.appendChild(priceElement);

    // Product variant title
    const variantTitle = item.variant.title;
    if (variantTitle && !variantTitle.toLowerCase().startsWith('default')) {
    const sizeElement = document.createElement('div');
    sizeElement.textContent = variantTitle;
    sizeElement.className = 'size';
    titlePriceSizeDiv.appendChild(sizeElement);
}

    // Quantity input
    const quantityInput = document.createElement('input');
    quantityInput.type = 'number';
    quantityInput.value = item.quantity;
    quantityInput.min = '1';
    quantityInput.className = 'quantity-input';

        // Log the lineItemId for each item
        console.log('Line Item ID:', item.id);
     // Store the line item ID in the data attribute of the quantity input
     quantityInput.dataset.lineItemId = item.id;

    handleQuantityInputChange(quantityInput);

    // Remove button
    const removeButton = document.createElement('button');
    removeButton.textContent = 'Remove';
    removeButton.className = 'remove-button';
    // Assuming you have a function to handle item removal
    removeButton.addEventListener('click', () => removeItemFromCart(item.id));

    // Create a new div for grouping quantityInput and removeButton
const groupDiv = document.createElement('div');
groupDiv.className = 'input-group';

// Append quantityInput and removeButton to groupDiv
groupDiv.appendChild(quantityInput);
groupDiv.appendChild(removeButton);

cartItemElement.appendChild(titlePriceSizeDiv);
// Append groupDiv to detailsDiv instead of individual elements
detailsDiv.appendChild(groupDiv);

    // Append details div to the cart item element
    cartItemElement.appendChild(detailsDiv);

    // Append the cart item element to the cart container
    cartContainer.appendChild(cartItemElement);
  });

    // Find or create the 'Cart' element with item count
    let cartTitleElement = document.getElementById('cartTitle');
    if (!cartTitleElement) {
        cartTitleElement = document.createElement('div');
        cartTitleElement.id = 'cartTitle';
        cartTitleElement.className = 'cart-title'; // Add appropriate class for styling
        cartDrawer.insertBefore(cartTitleElement, cartDrawer.firstChild); // Insert at the top

        // Add the word "Cart" with a separate span for styling
        const cartTextSpan = document.createElement('span');
        cartTextSpan.className = 'cart-text';
        cartTextSpan.textContent = 'Cart ';
        cartTitleElement.appendChild(cartTextSpan);

        // Create a span for the item count for separate styling
        const itemCountSpan = document.createElement('span');
        itemCountSpan.id = 'itemCount';
        itemCountSpan.className = 'item-count';
        cartTitleElement.appendChild(itemCountSpan);
    }

    // Calculate the total number of items
    const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0);
    document.getElementById('itemCount').textContent = totalItems; // Update the number

      // Update total price display
      updateTotalPriceDisplay(totalPrice, bottomContainer);

   // Update or create total price element
   let totalPriceElement = bottomContainer.querySelector('.total-price');
   if (!totalPriceElement) {
     totalPriceElement = document.createElement('div');
     totalPriceElement.className = 'total-price';
 
     const totalLabel = document.createElement('span'); // Create inside if condition
     totalLabel.className = 'total-label';
     totalLabel.textContent = 'Total ';
     totalPriceElement.appendChild(totalLabel);

     // Create a span for the price amount
const totalPriceAmount = document.createElement('span');
totalPriceAmount.className = 'total-price-amount';
totalPriceAmount.textContent = `€${totalPrice.toFixed(2)}EUR`;
totalPriceElement.appendChild(totalPriceAmount);
     
   }
   //totalPriceAmount.textContent = ` €${totalPrice.toFixed(2)} EUR`;
 
   let orderNoteLink = bottomContainer.querySelector('.order-note-link');
   let orderNoteTextarea = bottomContainer.querySelector('.order-note-textarea');
   let orderNoteSubmitButton = bottomContainer.querySelector('.order-note-submit');
   let orderNoteContainer = bottomContainer.querySelector('.order-note-container');

if (!orderNoteLink || !orderNoteTextarea || !orderNoteSubmitButton || !orderNoteContainer) {

  // Create 'Add order note' link
  orderNoteLink = document.createElement('div');
  orderNoteLink.textContent = 'Add order note';
  orderNoteLink.className = 'order-note-link';
  

  orderNoteContainer = document.createElement('div');
    orderNoteContainer.className = 'order-note-container';
    bottomContainer.appendChild(orderNoteContainer);

    orderNoteTextarea = document.createElement('textarea');
    orderNoteTextarea.placeholder = 'Order note';
    orderNoteTextarea.className = 'order-note-textarea';
    orderNoteContainer.appendChild(orderNoteTextarea);

    orderNoteSubmitButton = document.createElement('button');
    orderNoteSubmitButton.textContent = 'Save';
    orderNoteSubmitButton.className = 'order-note-submit';
    orderNoteContainer.appendChild(orderNoteSubmitButton);

    bottomContainer.appendChild(totalPriceElement);
    bottomContainer.appendChild(orderNoteLink);
    bottomContainer.appendChild(cartBtnContainer);

    orderNoteLink.addEventListener('click', function() {
      isNoteVisible = !isNoteVisible;
      orderNoteContainer.style.bottom = isNoteVisible ? '30%' : '-100%'; // Toggle position
  });
  
orderNoteSubmitButton.addEventListener('click', async function() {
  const note = orderNoteTextarea.value;
  if (note) {
      await updateCheckoutWithNote(note);
      orderNoteTextarea.style.display = 'none';
  }
});
}
}  


function updateTotalPriceDisplay(totalPrice, container) {
  let totalPriceElement = container.querySelector('.total-price');
  if (!totalPriceElement) {
      totalPriceElement = document.createElement('div');
      totalPriceElement.className = 'total-price';
      container.appendChild(totalPriceElement);
  }
  
  // Clear previous content
  totalPriceElement.innerHTML = '';

  // Create and append the total label and amount
  const totalLabel = document.createElement('span');
  totalLabel.className = 'total-label';
  totalLabel.textContent = 'Total ';
  totalPriceElement.appendChild(totalLabel);

  const totalPriceAmount = document.createElement('span');
  totalPriceAmount.className = 'total-price-amount';
  totalPriceAmount.textContent = `€${totalPrice.toFixed(2)} EUR`;
  totalPriceElement.appendChild(totalPriceAmount);
}

async function updateCheckoutWithNote(note) {
  const checkoutId = localStorage.getItem('checkoutId');
  if (!checkoutId) {
      console.error('No checkout ID found for adding order note');
      return;
  }

  // API call to update checkout with the order note
  try {
      const response = await fetch('/api/shopify/updateCheckout', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ checkoutId, note }),
      });

      const data = await response.json();
      console.log('Checkout updated with note:', data);
  } catch (error) {
      console.error('Error updating checkout with note:', error);
  }
}

// Function to handle input change for quantity
function handleQuantityInputChange(inputElement) {
  inputElement.addEventListener('change', async (event) => {
    const newQuantity = parseInt(event.target.value);
    if (newQuantity > 0) {
      const lineItemId = event.target.dataset.lineItemId;
      console.log('Attempting to update quantity:', { lineItemId, newQuantity });
      await updateQuantityInCart(lineItemId, newQuantity);
    }
  });
}

// Function to update quantity in the cart when the input changes
async function updateQuantityInCart(lineItemId, newQuantity) {
  let checkoutId = localStorage.getItem('checkoutId');
  try {
    await fetch('/api/shopify/updateCartItemQuantity', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ lineItemId, checkoutId, quantity: newQuantity }),
    });
    await updateCartDrawer(); // Fetch and update the cart drawer with updated cart data
  } catch (error) {
    console.error('Error in updating quantity:', error);
  }
}

// Function to remove an item from the cart
async function removeItemFromCart(lineItemId) {
    try {
      const checkoutId = localStorage.getItem('checkoutId');
      if (!checkoutId) {
        console.error('No checkout ID found');
        return;
      }
    const response = await fetch('/api/shopify/removeCartItem', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ lineItemId, checkoutId}),
    });
    const responseData = await response.json();
    localStorage.setItem('cartData', JSON.stringify(responseData)); // Update stored cart data
    updateCartDrawer(); // Update UI with the received data
  } catch (error) {
    console.error('Error removing item from cart:', error);
  }
}

// Add event listeners to quantity inputs and remove buttons
export async function setupCartListeners() {
  const cartContainer = document.getElementById('cartContainer');

  cartContainer.addEventListener('change', (event) => {
    const inputElement = event.target;
    if (inputElement.classList.contains('quantity-input')) {
      const variantId = inputElement.dataset.variantId;
      const newQuantity = inputElement.value;
      updateQuantityInCart(variantId, newQuantity);
    }
  });

  cartContainer.addEventListener('click', (event) => {
    const removeButton = event.target;
    if (removeButton.classList.contains('remove-button')) {
      const variantId = removeButton.dataset.variantId;
      removeItemFromCart(variantId);
    }
  });
}

  export async function captureScreenshot(element, scale = 2, format = 'image/jpeg') {
    // Function to capture a screenshot of a specific div using HTML2Canvas
    return await html2canvas(element, { scale: scale, logging: false })
      .then(canvas => {
        // Extract Base64 data from the canvas with the specified format
        const base64ImageData = canvas.toDataURL(format);
        return base64ImageData;
      });
  }
  
// Function to upload the image to the server
export async function uploadImageToS3(base64Image) {
    try {
      // Make a fetch request to the server endpoint
      const response = await fetch('/api/shopify/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ base64Image }),
      });
      if (!response.ok) {
        // Handle non-OK responses (e.g., server error)
        throw new Error('Error uploading image to server.');
      }
  
      // Parse the JSON response from the server
      const data = await response.json();
      // Handle the response data as needed (e.g., display image URL to the user)
      console.log('Image uploaded successfully:', data.imageUrl);
 
      return data.imageUrl;
    } catch (error) {
      // Handle network errors or other fetch-related issues
      console.error('Error uploading image to server:', error);
      throw error;
    }
  }  
