// materials.js

import { MeshStandardMaterial, MeshPhysicalMaterial, NormalBlending, DoubleSide, FrontSide } from 'three';

const materials = {
    customLogo: 
    new MeshStandardMaterial({ 
        color: null, 
        side: DoubleSide, 
        opacity: 0, 
        transparent: true, 
        blending: NormalBlending, 
        map: null
    }),

    customText: 
    new MeshStandardMaterial({ 
        color: 0xffffff, 
        side: DoubleSide, 
        opacity: 0, 
        transparent: true, 
        blending: NormalBlending,
        depthTest: true, 
        depthWrite: true,
        //polygonOffset: true,
        //polygonOffsetFactor: -5,
    }),

    force: 
    new MeshStandardMaterial({ 
        color: 0xffffff, 
        side: DoubleSide, 
        opacity: 1, 
        transparent: true, 
        blending: NormalBlending,
        depthTest: true, 
        depthWrite: true,
       // polygonOffset: true,
        //polygonOffsetFactor: -0.3,
    }), 
    harlemLeftLE: 
    new MeshStandardMaterial({ 
        color: 0xffffff, 
        side: FrontSide, 
        opacity: 0.9, 
        transparent: true, 
        blending: NormalBlending,
        depthTest: true, 
        depthWrite: true,
        //polygonOffset: true,
        //polygonOffsetFactor: -30,
    }),
    harlemRightLE: 
    new MeshStandardMaterial({ 
        color: 0xffffff, 
        side: FrontSide, 
        opacity: 0.9, 
        transparent: true, 
        blending: NormalBlending,
        depthTest: true, 
        depthWrite: true,
        //polygonOffset: true,
        //polygonOffsetFactor: -30,
     }),
    mainPrint: 
    new MeshPhysicalMaterial({ 
        transparent: true, 
        opacity: 0.985, 
        clearcoat: 0.3,
        clearcoatRoughness: 0.275,
        //clearcoatRoughness: 0.35,
        blending: NormalBlending, 
        side: DoubleSide, 
        depthTest: true, 
        depthWrite: true,
        //envMap: envMap,
        //envMapIntensity: 5
    }), // Set the color as needed
    //mainPrint_2: new THREE.MeshPhysicalMaterial({ color: 0xff0000, transparent: true, opacity: 0.98, side: THREE.DoubleSide, depthTest: true, depthWrite: true }), // Set the color as needed
    sizeCanopy:
    new MeshStandardMaterial({ 
        color: 0xffffff, 
        side: DoubleSide, 
        opacity: 0.9, 
        transparent: true, 
        blending: NormalBlending,
        depthTest: true, 
        depthWrite: true,
       // polygonOffset: true,
       // polygonOffsetFactor: -1,

    }),
    sizeLE: 
    new MeshStandardMaterial({ 
        color: 0xffffff, 
        side: FrontSide, 
        opacity: 0.9, 
        transparent: true, 
        blending: NormalBlending,
        depthTest: true, 
        depthWrite: true,
        //polygonOffset: true,
        //polygonOffsetFactor: -30,

    }),
  };

  export default materials;
  