// customizationOptions.js

const customizationOptions = [
  {
    meshName: 'mainPrint',
    materialName: 'mainPrint',
    printOptions: {
      'opt1': {
        textures: ['0.jpg', '-1.jpg', '-2.jpg', '-3.jpg', '1.jpg', '2.jpg','3.jpg'],
        swatchBackground: ['background.jpg'],
      },
      'opt2': {
        textures: ['0.jpg', '-1.jpg', '-2.jpg', '-3.jpg', '1.jpg', '2.jpg','3.jpg'],
        swatchBackground: ['background.jpg'],
      },
      'opt3': {
        textures: ['0.jpg', '-1.jpg', '-2.jpg', '-3.jpg', '1.jpg', '2.jpg','3.jpg'],
        swatchBackground: ['background.jpg'],
      },
      'opt4': {
        textures: ['0.jpg', '-1.jpg', '-2.jpg', '-3.jpg', '1.jpg', '2.jpg','3.jpg'],
        swatchBackground: ['background.jpg'],
      },
      'opt5': {
        textures: ['0.jpg', '-1.jpg', '-2.jpg', '-3.jpg', '1.jpg', '2.jpg','3.jpg'],
        swatchBackground: ['background.jpg'],
      },
      'opt6': {
        textures: ['0.jpg', '-1.jpg', '-2.jpg', '-3.jpg', '1.jpg', '2.jpg','3.jpg'],
        swatchBackground: ['background.jpg'],
      },
    },
    
  },
  {
    meshName: 'sizeCanopy',
    materialName: 'sizeCanopy',
    sizeOptions: {
      '6': {
        textures: ['size6_opt1.png', 'size6_opt2.png', 'size6_opt3.png', 'size6_opt4.png', 'size6_opt5.png', 'size6_opt6.png','size6_opt7.png', 'size6_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '7': {
        textures: ['size7_opt1.png', 'size7_opt2.png', 'size7_opt3.png', 'size7_opt4.png', 'size7_opt5.png', 'size7_opt6.png','size7_opt7.png', 'size7_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '8': {
        textures: ['size8_opt1.png', 'size8_opt2.png', 'size8_opt3.png', 'size8_opt4.png', 'size8_opt5.png', 'size8_opt6.png','size8_opt7.png', 'size8_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '9': {
        textures: ['size9_opt1.png', 'size9_opt2.png', 'size9_opt3.png', 'size9_opt4.png', 'size9_opt5.png', 'size9_opt6.png','size9_opt7.png', 'size9_opt8.png'],
              swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '10': {
        textures: ['size10_opt1.png', 'size10_opt2.png', 'size10_opt3.png', 'size10_opt4.png', 'size10_opt5.png', 'size10_opt6.png','size10_opt7.png', 'size10_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '11': {
        textures: ['size11_opt1.png', 'size11_opt2.png', 'size11_opt3.png', 'size11_opt4.png', 'size11_opt5.png', 'size11_opt6.png','size11_opt7.png', 'size11_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '12': {
        textures: ['size12_opt1.png', 'size12_opt2.png', 'size12_opt3.png', 'size12_opt4.png', 'size12_opt5.png', 'size12_opt6.png','size12_opt7.png', 'size12_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      // Add more size options here
    },
    swatchBackground: ['background.jpg'],
    colors: ['0xffffff'],
  },
  {
    meshName: 'force',
    materialName: 'force',
    textures: ['forceTest.png'],    
    colors: ['0xffffff'],
  },
  {
    meshName: 'sizeLE',
    materialName: 'sizeLE',
    sizeOptions: {
      '6': {
        textures: ['size6LE_opt1.png', 'size6LE_opt2.png', 'size6LE_opt3.png', 'size6LE_opt4.png', 'size6LE_opt5.png', 'size6LE_opt6.png','size6LE_opt7.png', 'size6LE_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '7': {
        textures: ['size7LE_opt1.png', 'size7LE_opt2.png', 'size7LE_opt3.png', 'size7LE_opt4.png', 'size7LE_opt5.png', 'size7LE_opt6.png','size7LE_opt7.png', 'size7LE_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '8': {
        textures: ['size8LE_opt1.png', 'size8LE_opt2.png', 'size8LE_opt3.png', 'size8LE_opt4.png', 'size8LE_opt5.png', 'size8LE_opt6.png','size8LE_opt7.png', 'size8LE_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '9': {
        textures: ['size9LE_opt1.png', 'size9LE_opt2.png', 'size9LE_opt3.png', 'size9LE_opt4.png', 'size9LE_opt5.png', 'size9LE_opt6.png','size9LE_opt7.png', 'size9LE_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '10': {
        textures: ['size10LE_opt1.png', 'size10LE_opt2.png', 'size10LE_opt3.png', 'size10LE_opt4.png', 'size10LE_opt5.png', 'size10LE_opt6.png','size10LE_opt7.png', 'size10LE_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '11': {
        textures: ['size11LE_opt1.png', 'size11LE_opt2.png', 'size11LE_opt3.png', 'size11LE_opt4.png', 'size11LE_opt5.png', 'size11LE_opt6.png','size11LE_opt7.png', 'size11LE_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      '12': {
        textures: ['size12LE_opt1.png', 'size12LE_opt2.png', 'size12LE_opt3.png', 'size12LE_opt4.png', 'size12LE_opt5.png', 'size12LE_opt6.png','size12LE_opt7.png', 'size12LE_opt8.png'],
        swatchBackground: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'],
      },
      // Add more size options here
    },
    swatchBackground: ['background.jpg'],
    colors: ['0xffffff'],
  },  
  {
    meshName: 'harlemLeftLE',
    materialName: 'harlemLeftLE',
    textures: ['9customWhiteDetailsForce.png'],    
    colors: ['0xffffff'],
  },
  {
    meshName: 'harlemRightLE',
    materialName: 'harlemRightLE',
    textures: ['9customWhiteDetailsForce.png'],    
    colors: ['0xffffff'],
  },  
  {
    meshName: 'customLogo',
    materialName: 'customLogo',
    textures: [null],
    colors: ['0xffffff'],
  },
  {
    meshName: 'customText',
    materialName: 'customText',
    textures: [null],
    colors: ['0xffffff'],
  }    
];

export { customizationOptions };