import { TextureLoader, RepeatWrapping, SRGBColorSpace } from 'three';

// Create a single instance of TextureLoader
const textureLoader = new TextureLoader();

// Object to store loaded textures
const loadedTextures = {};

// Function to load and cache textures
export async function loadAndApplyTextureToMaterial(material, texturePath) {
  const webpTexturePath = convertToWebP(texturePath);

  if (!loadedTextures[webpTexturePath]) {
    try {
      const texture = textureLoader.load(webpTexturePath);
      loadedTextures[webpTexturePath] = texture;
      material.map = texture;
      material.needsUpdate = true;
      material.map.wrapS = RepeatWrapping;
      material.map.wrapT = RepeatWrapping;
      texture.colorSpace = SRGBColorSpace;
      texture.flipY = false;
    } catch (error) {
      console.error(`Error loading texture ${webpTexturePath}:`, error);
      return;
    }
  } else {
    material.map = loadedTextures[webpTexturePath];
    material.needsUpdate = true;
  }

  // Dispose of the previous texture before reassigning
  if (material.map && material.map.dispose) {
    material.map.dispose();
  }

  // Assign the new texture to the material
  material.map = loadedTextures[webpTexturePath];
  material.needsUpdate = true;
}

// Function to convert texture path to WebP format
function convertToWebP(texturePath) {
  // Modify the texturePath to point to the WebP version
  // You might have a specific way of naming or organizing your WebP textures
  // For simplicity, let's assume you replace ".jpg" or ".png" with ".webp"
  return texturePath.replace(/\.(jpg|png)$/, '.webp');
}

// Function to dispose of textures
export function disposeTexture() {
  for (const webpTexturePath in loadedTextures) {
    const texture = loadedTextures[webpTexturePath];
    texture.dispose();
    delete loadedTextures[webpTexturePath];
  }
}

export { textureLoader };
