// Config.js

export const texturePaths = [
    'textures/sizeCanopy/',
    'textures/sizeLE/',
    'textures/harlemLeftLE/',
    'textures/harlemRightLE/',
    'textures/force/',
    'textures/mainPrint/'
  ];

export const hdrTexturePath = '/skybox/spiaggia_di_mondello_1k.hdr';


